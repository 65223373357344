// import application from "./assets/application.png";
// import { apple, apple_play_black, app_icon, arrow_back, arrow_down, arrow_forword, colored_logo, faq_qotes, f_icon, googleplay, google_play_black, header, logo } from "./assets/icons";
// import man_thinking from './assets/man_thinking.png';
// import mortah_app from './assets/mortah_app.png';
// import section2_background from './assets/section2_background.png';
// import section4 from './assets/section4.jpg';
// import store_app from './assets/store_app.png';
import Desktop from "./desktop";
import Mobile from "./mobile";
function App() {
  return (
    <>
      <Desktop />
      <Mobile />
    </>
  );
}

export default App;

import { useRef } from "react";
import application from "./assets/application.png";
import { apple, apple_play_black, app_icon, arrow_back, arrow_down, arrow_forword, colored_logo, faq_qotes, f_icon, googleplay, google_play_black, lang, logo, social } from "./assets/icons";
import man_thinking from './assets/man_thinking.png';
import mortah_app from './assets/mortah_app.png';
import section2_background from './assets/section2_background.png';
import section4 from './assets/section4.jpg';
import store_app from './assets/store_app.png';
import { android_customer, android_store, ios_customer, ios_store } from "./constant";

function Desktop() {

    const executeScroll = (ref) => {
        ref.current.scrollIntoView()
    }

    const Home = useRef(null)
    const Features = useRef(null)
    const FAQs = useRef(null)
    const Contact = useRef(null)

    return (
        <div className="hidden lg:block">
            <div className="overflow-x-hidden w-screen h-screen bg-header_desktop bg-cover bg-no-repeat">
                <div className="flex px-[10vw]">
                    <div className="pt-5 w-[197px] h-[73.59px]">
                        {logo}
                    </div>
                    <div className="w-28"></div>
                    <div className="pt-10 flex text-white text-[16px]">
                        <div className="" onClick={() => executeScroll(Home)}>
                            Home
                        </div>
                        <div className="w-20"></div>
                        <div className="" onClick={() => executeScroll(Features)}>
                            Features
                        </div>
                        <div className="w-20"></div>
                        <div className="" onClick={() => executeScroll(FAQs)}>
                            FAQs
                        </div>
                        <div className="w-20"></div>
                        <div className="" onClick={() => executeScroll(Contact)}>
                            Contact Us
                        </div>
                        <div className="w-20"></div>
                        <div className="w-20"></div>
                    </div>
                    <div className="pt-7">
                        <div className="flex justify-center items-center gap-5 text-white text-lg bg-white bg-opacity-20 w-[154px] h-[52px] rounded-full border-[1px] border-white border-opacity-50">
                            {lang}
                            عربي
                        </div>
                    </div>
                </div>

                <div className="h-10" />
                <div className="flex items-center px-32 justify-between">
                    <div className="flex flex-col">
                        <div className="flex flex-col text-5xl font-semibold text-white">
                            <span>
                                Pick, Pay and
                            </span>
                            <span>
                                receive
                            </span>
                        </div>
                        <div className="h-5" />
                        <div className="right-0 text-white text-[20px]">
                            Pick , Pay and Receive with a single press
                        </div>
                        <div className="h-5" />

                        <div className="text-black text-[18px] flex">
                            <a href={ios_customer} className="w-[220px] h-[70px] bg-[#F9F9F9] rounded-full flex px-5 justify-evenly items-center">
                                {apple}
                                <div className="">App store</div>
                            </a>
                            <div className="w-10"></div>
                            <a href={android_customer} className="w-[220px] h-[70px] bg-[#F9F9F9] rounded-full flex px-5 justify-evenly items-center">
                                {googleplay}
                                <div className="">Google play</div>
                            </a>
                        </div>
                    </div>
                    <div className="" style={{ width: 531, height: 604.65 }}>
                        <img src={application} alt="" />
                    </div>
                </div>

                <div className="mx-24">
                    <div className="flex justify-center items-center w-[88px] h-[88px] rounded-full bg-[#FFFFFF] backdrop-blur-sm border-[1px] border-opacity-20 border-white bg-opacity-[9%]">
                        {arrow_down}
                    </div>
                </div>
            </div>
            <div className="h-[99px]"></div>
            <section ref={Features}>

                <div className="w-screen flex justify-center items-center text-[45px] font-semibold">Join us as</div>
                <div className="w-screen flex justify-center items-center text-[16px] text-[#426666]">Join thousands of customers and stores on our e-commerce multi</div>
                <div className="w-screen flex justify-center items-center text-[16px] text-[#426666]">stores platform where you can find anthing you want</div>
                <div className="flex justify-between">
                    <div className="absolute flex flex-col pt-44 px-44">
                        {app_icon}
                        <div className="h-4"></div>
                        <div className="text-[36px] font-semibold">
                            Download Mortah app
                        </div>
                        <div className="h-4"></div>
                        <div className="flex flex-col">
                            easy access to the products, and smooth
                            <span>
                                ordering including payment and fix delivery
                            </span>
                            cost.
                        </div>
                        <div className="h-5"></div>
                        <div className="flex z-50">
                            <a href={android_customer} className="pt-1">
                                {google_play_black}
                            </a>
                            <div className="w-[32px]"></div>
                            <a href={ios_customer}>
                                {apple_play_black}
                            </a>
                        </div>
                    </div>

                    <div className="w-screen flex justify-end">
                        <img src={mortah_app} alt="" className="w-[65vw] max-w-5xl" />
                    </div>
                </div>
            </section>


            <div className="flex justify-between">
                <div className="flex flex-col absolute justify-end  pt-[35vh] right-0 px-44">
                    {app_icon}
                    <div className="h-4"></div>
                    <div className="text-[36px] font-semibold">
                        Download Mortah Sell app
                    </div>
                    <div className="h-4"></div>
                    <div className="flex flex-col">
                        build their online shop and track their sales
                        <span>
                            and manage their orders.
                        </span>
                    </div>
                    <div className="h-5"></div>
                    <div className="flex">
                        <a href={android_store} className="pt-1">
                            {google_play_black}
                        </a>
                        <div className="w-[32px]"></div>
                        <a href={ios_store} className="">
                            {apple_play_black}
                        </a>
                    </div>
                </div>
                <div className="w-screen flex justify-start">
                    <img src={store_app} alt="" className="w-[60vw] max-w-5xl" />
                </div>
            </div>

            <div className="h-20"></div>
            <div className="relative px-[140px] flex justify-center items-center">
                <img src={section2_background} alt="" className="rounded-[20px]" />
                {/* <img src={section2_phone} alt="" className="absolute w-[236.44px] top-[-14vh] right-80" /> */}
                <div className="absolute top-[40%] left-[15%] text-[40px] font-semibold text-white">
                    The best way to shop online
                </div>
                <div className="flex flex-col absolute top-[55%] left-[15%] text-[19px] text-white">
                    <span>
                        easy access to the products, and smooth ordering
                    </span>
                    <span>
                        including payment and fix delivery cost.
                    </span>
                </div>
                <div className="absolute left-[15%] bottom-10">
                    <a href={android_customer} className="w-[220px] h-[70px] bg-[#F9F9F9] rounded-full flex justify-center items-center text-[22px]">
                        Get it now !
                    </a>
                </div>
            </div>
            <div className="h-24" />
            <section ref={FAQs}>
                <div className="flex justify-center items-center">
                    <div className=""></div>
                    {faq_qotes}
                    <div className="w-10" />
                    <div className="flex flex-col items-center justify-center">
                        <div className="text-[45px] font-semibold">FAQs</div>
                        <div className="text-[16px] flex flex-col justify-center items-center">
                            <span>
                                The most asked questions about the app , features , subscription
                            </span>
                            <span>
                                and alot more . if you still have questions please contact us
                            </span>
                        </div>
                    </div>
                    <div className=""></div>
                </div>
            </section>
            <div className="h-10" />

            <div className="flex justify-between items-center">
                <div className=""></div>
                <div className=""></div>
                <div className="flex flex-col">
                    {f_icon}
                    <div className="flex flex-col font-semibold">
                        <span className="text-[25px]">
                            If i want to subscribe as a store how
                        </span>
                        <span className="text-[25px]">
                            much does it cost ?
                        </span>
                    </div>
                    <div className="pt-2">
                        Monthly subscription fee is Free Now
                    </div>
                </div>
                <div className="w-36" />
                <img src={man_thinking} alt="" className="w-96" />
            </div>
            <div className="flex justify-center items-center">
                <div className="w-10 h-10 bg-white shadow-lg rounded-md shadow-zinc-400 flex justify-center items-center">
                    {arrow_back}
                </div>
                <div className="w-6" />
                <div className="w-10 h-10 bg-white shadow-lg rounded-md shadow-zinc-400  flex justify-center items-center">
                    {arrow_forword}
                </div>
            </div>
            <div className="h-52"></div>
            <a href="mailto: Info@morta7.com">
                <img src={section4} alt="" />
            </a>
            <section ref={Contact}>

                <div className="h-20" />
                <div className="flex items-center justify-between px-20 text-[16px]">
                    <div className="">
                        {colored_logo}
                    </div>
                    <div className=""></div>
                    <div className="">Terms and Conditions</div>
                    <div className="">Privacy Policy</div>
                    <div className="">
                        {social}
                    </div>
                </div>
            </section>

        </div>
    );
}

export default Desktop;

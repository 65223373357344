import { useRef, useState } from 'react';
import { Fade, Slide } from 'react-awesome-reveal';
import apple_store_black_button from './assets/apple_store_black_button.png';
import application from "./assets/application.png";
import customer_application_mobile from './assets/customer_application_mobile.png';
import f_icon from './assets/f_icon.png';
import google_play_black_button from './assets/google_play_black_button.png';
import { apple, app_icon, arrow_back, arrow_down, arrow_forword, close, colored_logo_mobile, faq_qotes_mobile, googleplay, lang_mobile, logo, menue } from "./assets/icons";
import man_thinking from './assets/man_thinking.png';
import section2_mobile from './assets/section2_mobile.png';
import section4_mobile from './assets/section4_mobile.png';
import store_application_mobile from './assets/store_application_mobile.png';
import { android_customer, android_store, ios_store } from './constant';

function Mobile() {
    const [isOpen, setIsOpen] = useState(false)
    const executeScroll = (ref) => {
        setIsOpen(false)
        ref.current.scrollIntoView()
    }

    const Home = useRef(null)
    const Features = useRef(null)
    const FAQs = useRef(null)
    const Contact = useRef(null)

    return (
        <div className="visible lg:hidden w-screen">
            <Fade onVisibilityChange={isOpen} reverse={!isOpen}>
                <div className={`absolute ${isOpen ? "flex" : "hidden"} h-80 w-screen top-0 bg-white backdrop-blur-3xl flex-col py-10 px-5`}>
                    <div className="flex justify-between" >
                        <div className="w-20" onClick={() => setIsOpen(false)}>
                            {close}
                        </div>
                        <div className="text-black flex">
                            {lang_mobile}
                            <div className="w-2"></div>
                            <div className="">عربي</div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-9 text-lg font-bold justify-center items-center">
                        <div className="" onClick={() => executeScroll(Home)}>Home</div>
                        <div className="" onClick={() => executeScroll(Features)}>Features</div>
                        <div className="" onClick={() => executeScroll(FAQs)}>FAQs</div>
                        <div className="" onClick={() => executeScroll(Contact)}>Contact Us</div>
                    </div>
                </div>
            </Fade>
            <div className="w-screen bg-header_mobile bg-no-repeat bg-cover">
                <div className="flex justify-between px-[5vw] pt-5">
                    <div className="w-[197px] h-[73.59px]">
                        {logo}
                    </div>
                    <div className="pt-5" onClick={() => setIsOpen(true)}>
                        {menue}
                    </div>
                </div>
                <div className="h-28" />
                <div className="flex flex-col justify-center items-center text-[37.05px] text-white font-semibold">
                    Pick, Pay and
                    <span>
                        receive
                    </span>
                </div>
                <div className="h-10" />
                <div className="flex flex-col justify-center items-center  text-white text-[20px]">
                    All your favourite stores in one  place !
                </div>
                <div className="h-10" />

                <div className="text-black flex justify-center items-center text-[10px] font-semibold">
                    <a href={ios_store} className="w-[130px] h-[42px] bg-[#F9F9F9] rounded-full flex px-2 justify-evenly items-center" >
                        {apple}
                        <div className="">App store</div>
                    </a>
                    <div className="w-10"></div>
                    <a href={android_store} className="w-[130px] h-[42px] bg-[#F9F9F9] rounded-full flex px-2 justify-evenly items-center">
                        {googleplay}
                        <div className="">Google play</div>
                    </a>
                </div>
                <div className="h-10" />
                <div className="flex justify-center items-center">
                    <img src={application} alt="" />
                </div>
                <div className="flex justify-center items-center" onClick={() => executeScroll(Features)}>
                    <div className="flex justify-center items-center  w-[88px] h-[88px] rounded-full bg-[#FFFFFF] backdrop-blur-sm border-[1px] border-opacity-20 border-white bg-opacity-[9%]">
                        {arrow_down}
                    </div>
                </div>
                <div className="h-5" />
            </div>
            <div className="h-14"></div>
            <div className="w-screen flex justify-center items-center text-3xl font-semibold">Join us as</div>
            <div className="flex flex-col justify-center items-center text-sm px-5 text-[#426666]">
                <p>Join thousands of customers and stores on our e-commerce multi</p>
                <p>stores platform where you can find anthing you want</p>
            </div>
            {/* Features */}
            <section ref={Features}>
                <>
                    <Slide direction='up' triggerOnce>
                        <div className="w-screen flex" ref={Features}>
                            <img src={customer_application_mobile} alt="" className="" />
                        </div>
                    </Slide>
                    <div className="pl-20">
                        {app_icon}
                        <div className="h-5" />
                        <div className="text-[32px] font-semibold">
                            Download Mortah app
                        </div>
                        <div className="h-3" />
                        <div className="flex flex-col text-sm">
                            easy access to the products, and smooth
                            <span>
                                ordering including payment and fix delivery
                            </span>
                            cost.
                        </div>
                    </div>
                    <div className="h-10" />
                    <div className="flex justify-evenly px-14">
                        <a href={android_customer}>
                            <img src={google_play_black_button} className="w-40" alt="" />
                        </a>
                        <a href={ios_store}>
                            <img src={apple_store_black_button} className="w-40" alt="" />
                        </a>
                    </div>
                </>
                <>
                    <Slide direction='up' triggerOnce>
                        <div className="w-screen flex">
                            <img src={store_application_mobile} alt="" className="" />
                        </div>
                    </Slide>
                    <div className="pl-20">
                        {app_icon}
                        <div className="h-5" />
                        <div className="text-[32px] font-semibold">
                            Download Mortah sell app
                        </div>
                        <div className="h-3" />
                        <div className="flex flex-col text-sm">
                            build their online shop and track their sales
                            <span>
                                and manage their orders.
                            </span>
                        </div>
                    </div>
                    <div className="h-10" />
                    <div className="flex justify-evenly px-14">
                        <a href={android_store}>
                            <img src={google_play_black_button} className="w-40" alt="" />
                        </a>
                        <a href={ios_store}>
                            <img src={apple_store_black_button} className="w-40" alt="" />
                        </a>
                    </div>
                </>
            </section>

            <div className="h-20"></div>

            <div className="px-4 flex items-center">
                <Slide direction='up' reverse={false} triggerOnce>
                    <a href={android_store}>
                        <img src={section2_mobile} alt="" />
                    </a>
                </Slide>
            </div>

            <div className="h-10" />

            {/* FAQs */}
            <section ref={FAQs}>
                <Slide direction='up' triggerOnce>
                    <div className="flex justify-between px-10">
                        {faq_qotes_mobile}
                        <div className="self-center justify-self-center text-3xl font-semibold">FAQs</div>
                        <div className=""></div>
                    </div>
                    <div className="h-4" />
                    <div className="px-4 flex flex-col items-center justify-center">
                        <div className="text-xs text-[#426666] flex flex-col justify-center items-center ">
                            <span>
                                The most asked questions about the app , features , subscription
                            </span>
                            <span>
                                and alot more . if you still have questions please contact us
                            </span>
                        </div>
                    </div>
                    <div className="h-10" />
                    <img src={man_thinking} className="px-14" alt="" />
                    <div className="h-8" />
                    <img src={f_icon} alt="" />
                    <div className="flex flex-col px-10 text-lg font-semibold">
                        <p>If i want to subscribe as a store how</p>
                        <p>much does it cost ?</p>
                    </div>
                    <div className="h-5" />
                    <div className="px-10 text-lg italic">
                        Monthly subscription fee is <span className='text-[#145DE8]'>Free</span> Now
                    </div>
                </Slide>
            </section>
            <div className="h-10" />

            <div className="flex justify-end items-center px-10">
                <div className="w-10 h-10 bg-white shadow-lg rounded-md shadow-zinc-400 flex justify-center items-center">
                    {arrow_back}
                </div>
                <div className="w-6" />
                <div className="w-10 h-10 bg-white shadow-lg rounded-md shadow-zinc-400  flex justify-center items-center">
                    {arrow_forword}
                </div>
            </div>
            <div className="h-12"></div>

            {/* Contact */}
            <section ref={Contact}>
                <a href="mailto: Info@morta7.com">
                    <img src={section4_mobile} alt="" />
                </a>
                <div className="h-20" />
                <div className="flex items-center text-[16px] justify-between px-10">
                    <div className="">
                        {colored_logo_mobile}
                    </div>
                    <div className="text-xs">Terms and Conditions</div>
                    <div className="text-xs">Privacy Policy</div>
                </div>
            </section>

        </div >
    );
}

export default Mobile;
